import type { ChangeEventHandler, FunctionComponent } from "react";
import type { RootState } from "../../../store";
import { useEffect, useMemo, useState } from "react";
import { Grid, Link, TextField } from "@mui/material";
import TooltipButton from "../../../components/TooltipButton";
import FixCMPCallMenu from "./FixCMPCallMenu";
import CQ from "../../../utilities/socket/CQ";
import { determineFaceTimeSupport } from "@wolzienllc/vcc-react-commons";
import { branding, participantTypeEnums } from "../../../utilities/definitions";
import { sanitizeRoomName } from "../../../utilities/utilityFunctions";
import ChangeFacetimeContact from "./ChangeFacetimeContact";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  critical_error,
  setServerMessage,
  zoomHideWarning,
} from "../../../store/queue/actions";
import { useDispatch, useSelector } from "../../../hooks/redux";
import CustomTooltip from "../../../components/Tooltip";
import AlertDialog from "../../../components/AlertDialog";
import { useSnackbar } from "../../../hooks/snackbar";

type Props = {
  appName: string;
  callHandle: string;
  cmp?: any;
  contacts?: any[];
  deviceInfo: any;
  email?: string;
  endpointID: string;
  guid: string;
  handleCallClick: any;
  inCall: string;
  isParticipantConnected: boolean;
  isRecommendedApp?: boolean;
  participantType: string;
  participantID: string;
  phoneNumber: string;
  isWaitingForCallStateChange: boolean;
  zoomApiIsAvailable: boolean;
};

// Renders all the call buttons associated with a particular app
const CallButtonGroup: FunctionComponent<Props> = (props) => {
  const {
    appName,
    callHandle,
    cmp,
    contacts,
    endpointID,
    deviceInfo,
    guid,
    handleCallClick,
    inCall,
    isParticipantConnected,
    isRecommendedApp = false,
    participantType,
    isWaitingForCallStateChange,
    zoomApiIsAvailable,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { apps: cmpApplications, isCallFullscreen, version } = cmp;

  const [isEditing, setIsEditing] = useState(false);
  const [newCallHandle, setNewCallHandle] = useState(callHandle);

  const isInCall = Boolean(inCall);

  const isProducerFaceTimeCompatible =
    Boolean(cmp) || determineFaceTimeSupport();

  const hasCMP = Boolean(cmp);

  const { isAwake: cmpSessionStarted, isUpdatingSession: cmpSessionChanging } =
    cmp ?? {};

  const participantIsQueue = participantType === participantTypeEnums.QUEUE;

  /**
   * @todo Make queue participants also use webrtcCompatible property
   */
  const isParticipantWebRTCCompatible =
    deviceInfo?.webrtcCompatible ?? deviceInfo?.supportsWebRTC;
  const isActiveCallApp =
    isInCall && inCall?.toLowerCase() === appName.toLowerCase();
  const parsedAppName =
    appName === "Twilio"
      ? branding.twilio
      : appName === "Zoom"
      ? branding.zoom
      : appName;
  const isFaceTimeCall = appName === "Facetime";
  const isNextomeetCall = appName === "NexToMeet";
  const isZoomCall = appName === "Zoom";
  const enableZoom = isZoomCall;

  const isCmpV2 =
    cmp && typeof cmp?.version !== "undefined" && cmp?.version >= 2;

  /**
   * *@todo Standardize deviceInfo between StageDoor and Queue participants
   */
  const deviceOs =
    deviceInfo?.deviceOs?.toLowerCase() ?? deviceInfo?.os?.name?.toLowerCase();

  const isParticipantFaceTimeCompatible =
    deviceOs === "macos" || deviceOs === "ios";

  const hasFacetimeContact = contacts?.some((contact) => {
    return contact.type === "facetime" && contact.value !== "";
  });

  const isPrescreen = endpointID.toLowerCase().indexOf("prescreen") > -1;
  const isPrescreenNoCmp = isPrescreen && !hasCMP;

  const [appIsDisabledByCMP, setAppIsDisabledByCMP] = useState<boolean>(false);

  useEffect(() => {
    if (Boolean(cmpApplications) && hasCMP && !isZoomCall) {
      const cmpApplicationsArray = cmpApplications.split(",");
      const appInCmpApplications = cmpApplicationsArray.find(
        (a: string) => a.toLowerCase() === appName.toLowerCase()
      );
      const exists = Boolean(appInCmpApplications);
      setAppIsDisabledByCMP(!exists);
    }
    return () => {
      /* Do nothing */
    };
  }, [appName, cmpApplications, hasCMP, isZoomCall, setAppIsDisabledByCMP]);

  const buttonColor = useMemo(() => {
    if (isInCall) {
      return isActiveCallApp ? "secondary" : "primary";
    }

    if (
      (isFaceTimeCall &&
        hasFacetimeContact &&
        !isParticipantFaceTimeCompatible) ||
      (!isFaceTimeCall &&
        isParticipantConnected &&
        !isParticipantWebRTCCompatible)
    ) {
      return "warning";
    }

    if (isZoomCall) {
      return "warning";
    }

    return "success";
  }, [
    hasFacetimeContact,
    isActiveCallApp,
    isInCall,
    isFaceTimeCall,
    isParticipantConnected,
    isParticipantFaceTimeCompatible,
    isParticipantWebRTCCompatible,
    isZoomCall,
  ]);

  const isButtonDisabled = useMemo(() => {
    if (isWaitingForCallStateChange) {
      return true;
    }

    if (isInCall) {
      if (isActiveCallApp) {
        return false;
      }
      return true;
    }

    if (cmp) {
      if (appIsDisabledByCMP) {
        return true;
      }
      if (!cmp.isAwake || cmp.isUpdatingSession) {
        return true;
      }
    }

    if (isFaceTimeCall) {
      return isProducerFaceTimeCompatible ? !hasFacetimeContact : true;
    }

    if (isZoomCall) {
      if (cmp && !isCmpV2) {
        return true;
      } else if (!zoomApiIsAvailable) {
        return true;
      } else {
        return !isParticipantConnected;
      }
    }

    return !isParticipantConnected;
  }, [
    appIsDisabledByCMP,
    cmp,
    enableZoom,
    hasFacetimeContact,
    isInCall,
    isActiveCallApp,
    isFaceTimeCall,
    isParticipantConnected,
    isProducerFaceTimeCompatible,
    isWaitingForCallStateChange,
    isCmpV2,
    isNextomeetCall,
    isZoomCall,
    zoomApiIsAvailable,
  ]);

  const buttonTooltip = useMemo(() => {
    if (isInCall) {
      if (isActiveCallApp) {
        return `End Call and Hang Up`;
      } else {
        return `End Call on other app to continue`;
      }
    }
    if (hasCMP && cmpSessionChanging) {
      return `Cannot start or end calls while machine session state is changing`;
    }
    if (hasCMP && !cmpSessionStarted) {
      return `Press the 'Start Session' button to make calls using this Control Panel`;
    }
    if (hasCMP && appIsDisabledByCMP) {
      return `The allocated machine is not configured to launch calls with this application.  Contact ${branding.airfirst} Support if you believe this is an error.`;
    }
    if (!isFaceTimeCall && !isParticipantConnected) {
      return `Waiting for participant to open their link`;
    }
    if (isFaceTimeCall && !isProducerFaceTimeCompatible) {
      return `***YOU*** are not on a FaceTime capable device; allocate a machine or switch to a macOS environment`;
    }
    if (isFaceTimeCall && !callHandle) {
      return "Click Edit to create or choose Apple/Facetime ID";
    }
    if (isFaceTimeCall && callHandle && !isParticipantFaceTimeCompatible) {
      return `Initiate ${parsedAppName} Call, but participant may not be on a FaceTime capable device`;
    }
    if ((isNextomeetCall || isZoomCall) && hasCMP && !isCmpV2) {
      return `Cannot start ${parsedAppName} call on this device due to legacy automation software.  Contact ${branding.airfirst} Support to upgrade this machine.`;
    }
    if (
      isZoomCall &&
      ((!hasCMP && isParticipantConnected) ||
        (hasCMP && isCmpV2 && isParticipantConnected))
    ) {
      if (zoomApiIsAvailable) {
        return `Initiate ${parsedAppName} Call.  Please allow extra time for the guest to connect as they may not have Zoom installed on their device.`;
      } else {
        return `${branding.zoom} Calls are not available at this time.`;
      }
    }
    return `Initiate ${parsedAppName} Call${
      isParticipantConnected && !isParticipantWebRTCCompatible
        ? ", but browser is potentially incompatible."
        : ""
    }`;
  }, [
    appIsDisabledByCMP,
    callHandle,
    cmpSessionChanging,
    cmpSessionStarted,
    hasCMP,
    isInCall,
    isActiveCallApp,
    isFaceTimeCall,
    isParticipantConnected,
    isParticipantFaceTimeCompatible,
    isParticipantWebRTCCompatible,
    isProducerFaceTimeCompatible,
    isCmpV2,
    isZoomCall,
    parsedAppName,
    zoomApiIsAvailable,
  ]);

  const updateHandleForApp = (app: string, handle: string): void => {
    CQ.emit("manuallyChangeAppHandle", { app, handle });
    setIsEditing(false);
  };

  const updateHandleClose = () => {
    setNewCallHandle(callHandle);
    setIsEditing(false);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const sanitizedHandle = sanitizeRoomName(event.target.value);
    setNewCallHandle(sanitizedHandle);
  };

  const editCallHandlePlaceholder = `Enter a ${
    isFaceTimeCall ? "Apple/FaceTime ID" : `${parsedAppName} call handle`
  }`;

  const callButtonText = useMemo(() => {
    if (isInCall && isActiveCallApp) {
      return `End Call`;
    }
    if (isInCall && !isActiveCallApp) {
      return `--- In Another Call ---`;
    }
    if (!isFaceTimeCall && !isParticipantConnected) {
      return `Awaiting Guest Connection`;
    }
    if (!callHandle && isFaceTimeCall && isProducerFaceTimeCompatible) {
      return `--- Enter Apple ID ---`;
    }
    if (!callHandle && isFaceTimeCall && !isProducerFaceTimeCompatible) {
      return `--- Option Unavailable ---`;
    }
    return `Start Call ${isFaceTimeCall ? ` [${callHandle}]` : ""}`;
  }, [
    callHandle,
    isInCall,
    isActiveCallApp,
    isFaceTimeCall,
    isParticipantConnected,
    isProducerFaceTimeCompatible,
    parsedAppName,
  ]);

  const callButtonIcon: IconProp = useMemo(() => {
    if (isInCall) {
      if (isActiveCallApp) {
        return ["fas", "video-slash"];
      } else {
        return undefined;
      }
    }
    if (
      (isFaceTimeCall && !isParticipantFaceTimeCompatible) ||
      (!isFaceTimeCall &&
        isParticipantConnected &&
        !isParticipantWebRTCCompatible) ||
      isZoomCall
    ) {
      return ["fas", "exclamation-triangle"];
    }
    return ["fas", "video"];
  }, [
    isActiveCallApp,
    isFaceTimeCall,
    isInCall,
    isParticipantConnected,
    isParticipantFaceTimeCompatible,
    isParticipantWebRTCCompatible,
  ]);

  const dispatch = useDispatch();

  const {
    criticalError,
    displayZoomPrescreenWarning,
    serverMessage,
    nextomeetProducerUrl,
    zoomHostUrl: zoomUrl,
  } = useSelector((state: RootState) => state.queue);

  const [disableOverride, setDisableOverride] = useState<boolean>(false);

  const cmpZoomCallDisableOverride = () => {
    let endCallTimeout = undefined;

    if (!isInCall) {
      setDisableOverride(true);
      endCallTimeout = setTimeout(() => {
        setDisableOverride(false);
        clearTimeout(endCallTimeout);
        endCallTimeout = undefined;
      }, 10000);
    }
  };

  const isFixButtonDisabled = cmp
    ? !isActiveCallApp || isWaitingForCallStateChange
    : true;

  const [showZoomWarning, setShowZoomWarning] = useState<boolean>(
    isZoomCall && displayZoomPrescreenWarning
  );

  const [nxtmProducerIsOpen, setNxtmProducerIsOpen] = useState<boolean>(false); // TODO for the close and option functionality to work properly this needs to be punted to a much higher level

  let nxtmProducerWindow = undefined; // TODO for the close and option functionality to work properly this needs to be punted to a much higher level

  const clickNextomeetProducerLauncher = () => {
    // TODO for the close and option functionality to work properly this needs to be punted to a much higher level
    if (nxtmProducerIsOpen) {
      try {
        nxtmProducerWindow.close();
      } catch (e: any) {
        console.warn(
          `Could not close ${branding.nextomeet} controls popup, may already be closed.`
        );
      }
      nxtmProducerWindow = undefined;
      setNxtmProducerIsOpen(false);
    } else {
      try {
        nxtmProducerWindow = window.open(
          nextomeetProducerUrl,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes"
        );

        setNxtmProducerIsOpen(true);
      } catch (e: any) {
        console.error(
          `Unable to launch local ${branding.nextomeet} controls popup`,
          e
        );
        nxtmProducerWindow = undefined;
        setNxtmProducerIsOpen(false);
      }
    }
  };

  const clickZoomLauncher = () => {
    try {
      document.getElementById("zoom-launcher-link")?.click();
    } catch (e: any) {
      console.error(`Unable to launch local ${branding.zoom} call`, e);
    }
  };

  useEffect(() => {
    if (
      isZoomCall &&
      isPrescreenNoCmp &&
      zoomUrl &&
      showZoomWarning === false
    ) {
      clickZoomLauncher();
    }

    return () => {};
  }, [isPrescreenNoCmp, isZoomCall, showZoomWarning, zoomUrl]);

  return (
    <>
      <Grid
        alignItems="center"
        container
        item
        justifyContent="space-between"
        spacing={1}
      >
        <Grid
          className={"layout-screener-callButtonsAppNameColumn"}
          item
          sm={2}
        >
          {parsedAppName}&nbsp;
          {isRecommendedApp && (
            <CustomTooltip
              placement="top"
              title={`${branding.airfirst} has determined that ${parsedAppName} is probably the best application to use for this participant.`}
            >
              <span style={{ color: "goldenrod" }}>★</span>
            </CustomTooltip>
          )}
        </Grid>

        <Grid item sm={6}>
          {isEditing ? (
            <CustomTooltip
              placement="right"
              title={`Editing ${parsedAppName} handle`}
            >
              <TextField
                className="fullWidth"
                error={newCallHandle !== callHandle}
                label={
                  newCallHandle !== callHandle ? "Save Changes" : "Editing"
                }
                placeholder={editCallHandlePlaceholder}
                size="small"
                value={newCallHandle}
                variant="outlined"
                onChange={handleChange}
              />
            </CustomTooltip>
          ) : (
            <TooltipButton
              className="call-button-control"
              color={buttonColor}
              icon={callButtonIcon}
              isDisabled={disableOverride || isButtonDisabled}
              placement="right"
              text={callButtonText}
              title={buttonTooltip}
              onClick={() => {
                setIsEditing(false);

                if (isZoomCall) {
                  cmpZoomCallDisableOverride();
                }

                if (isInCall) {
                  if (
                    isZoomCall &&
                    displayZoomPrescreenWarning &&
                    !showZoomWarning
                  ) {
                    setShowZoomWarning(displayZoomPrescreenWarning);
                  }
                } else {
                  if (criticalError?.length > 0) {
                    dispatch(critical_error(null));
                  }
                  if (serverMessage?.length > 0) {
                    dispatch(setServerMessage(null));
                  }
                }

                handleCallClick(appName);
              }}
            />
          )}
        </Grid>

        <Grid container item justifyContent="flex-start" sm={4} spacing={1}>
          <Grid item xs={6}>
            {isEditing && (
              <TooltipButton
                className="fullWidth"
                color="primary"
                isDisabled={newCallHandle === callHandle}
                text={"Save"}
                title={"Save Changes"}
                onClick={() => {
                  updateHandleForApp(appName, newCallHandle);
                }}
              />
            )}
            {!isEditing && isFaceTimeCall && participantIsQueue && (
              <TooltipButton
                className="call-button-control"
                color={"primary"}
                icon={["fas", "edit"]}
                isDisabled={isActiveCallApp}
                placement="top"
                text="Edit"
                title={
                  isActiveCallApp
                    ? `Cannot edit ${parsedAppName} call handle while in call on that app`
                    : `Edit ${parsedAppName} call handle`
                }
                onClick={() => setIsEditing(true)}
              />
            )}
            {!isEditing && isFaceTimeCall && !participantIsQueue && (
              <ChangeFacetimeContact
                contacts={contacts}
                deviceInfo={deviceInfo}
                guid={guid}
                isDisabled={isActiveCallApp}
              />
            )}
            {!Boolean(cmp) && isNextomeetCall && (
              <TooltipButton
                className="fullWidth"
                color={"primary"}
                isDisabled={
                  !isActiveCallApp || (!nextomeetProducerUrl && !isInCall)
                }
                text={"Controls"}
                title={
                  isActiveCallApp
                    ? `Access ${branding.nextomeet} controls`
                    : `Please start a ${branding.nextomeet} call to access controls`
                }
                onClick={clickNextomeetProducerLauncher}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {isEditing && (
              <TooltipButton
                className="fullWidth"
                color="secondary"
                text={"Cancel"}
                title={"Cancel Editing"}
                onClick={updateHandleClose}
              />
            )}
            {!isEditing && (
              <FixCMPCallMenu
                appName={appName}
                className="fullWidth"
                isCallFullscreen={isCallFullscreen}
                isDisabled={isFixButtonDisabled}
                nextomeetProducerLauncher={
                  isNextomeetCall ? clickNextomeetProducerLauncher : undefined
                }
                tooltipTitle={
                  Boolean(cmp) ? "Fix Call Options" : "No Allocated CMP"
                }
                version={version}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {!hasCMP && isZoomCall && zoomUrl && (
        <Link
          href={zoomUrl}
          id={"zoom-launcher-link"}
          rel="noopener noreferrer"
          style={{ display: "none" }}
          target="_blank"
        />
      )}

      {isPrescreenNoCmp && isZoomCall && zoomUrl && (
        <AlertDialog
          actions={[
            {
              closeOnClick: true,
              label: "Do not show this again",
              onClick: () => {
                enqueueSnackbar(
                  `You will not see Zoom application warnings on Prescreen Control Panels for 24 hours.`,
                  { variant: "info" }
                );
                dispatch(zoomHideWarning());
              },
            },
          ]}
          cancelText="Continue"
          dialogText={`Please make that your Zoom application is open.  Press "Do Not Show This Again" to hide this message for 24 hours.`}
          dialogTitle={`Preparing to launch ${branding.zoom} call`}
          handleClose={() => {
            setShowZoomWarning(false);
          }}
          isOpen={showZoomWarning}
        />
      )}
    </>
  );
};

export default CallButtonGroup;
