import * as Ramda from "ramda";
import { addDays, isAfter, isBefore, startOfDay } from "date-fns";
import CQ from "../../utilities/socket/CQ";
import { logger } from "../../utilities/logger";
import sortAndFilter from "../../utilities/participantSortingAndFiltering.js";
import {
  branding,
  localTimeZone,
  localStorageKey,
  scheduledCallDateAndTimeFormat,
  isAfterTwilioSoftShutoffDate,
} from "../../utilities/definitions";
import {
  dateToFormattedString,
  determineParticipantStatus,
} from "../../utilities/utilityFunctions";

import {
  CONTROL_PANEL_UPDATED,
  PARTICIPANT_CREATED,
  PARTICIPANT_DELETED,
  PARTICIPANT_UPDATED,
  SET_CLIENT_CONFIGURATION,
  SET_PARTICIPANTS,
  PRODUCTION_EVENT_CREATED,
  PRODUCTION_EVENT_DELETED,
  PRODUCTION_EVENT_UPDATED,
  SET_PRODUCTION_EVENTS,
  PARTICIPANT_ADDED_TO_PRODUCTION_EVENT,
  PARTICIPANT_REMOVED_FROM_PRODUCTION_EVENT,
  SET_CONTROL_PANELS,
  ADD_QUEUE_PARTICIPANT,
  ON_SOCKET_CONNECT,
  SELECT_NETWORK,
  STORE_FORCED_LOGOUT_RECOVERY_DATA,
  SELECT_ROLE,
  SET_USER_INFO,
  SET_ROLE_CHOICES,
  ALLOCATE_CMP,
  CMP_LIST,
  PARTICIPANT_EAVESDROP_STATUS,
  PROGRAM_EAVESDROP_STATUS,
  PROGRAM_CMP,
  TOGGLE_AUDIO,
  SET_FILTERS,
  SET_ZIP,
  SET_HOSTED_NETWORKS,
  UPDATE_QUEUE_PARTICIPANT,
  REMOVE_QUEUE_PARTICIPANT,
  SHOW_INFO,
  SERVER_OFFSET,
  SELECT_SCHEDULE_RANGE,
  HOUSEKEEPING,
  HOUSEKEEPING_ACK,
} from "./types";

const initialToday = new Date();
const startOfInitialToday = startOfDay(initialToday); // Initializes at midnight so events earlier than current time are still displayed

const suppressZoomWarningLocalStorageValue = localStorage.getItem(
  localStorageKey.suppressZoomWarning
);

const displayZoomPrescreenWarning =
  suppressZoomWarningLocalStorageValue === null
    ? true
    : isAfter(initialToday, new Date(suppressZoomWarningLocalStorageValue));

const initialState = {
  // If a redux store property isn't defined here, it WILL NOT WORK down the line
  attemptedQueueJoinsCount: 0,
  callers: {},
  clientConfiguration: null,
  cmps: {},
  criticalError: null,
  currentEpisode: false,
  currentRole: { name: "callProducer", alias: "Call Producer", value: true },
  currentScreener: null,
  currentShow: false,
  disconnected: true,
  displayZoomPrescreenWarning: displayZoomPrescreenWarning,
  episodes: {},
  episodeAllowingNewQueueParticipants: true,
  episodeAllowsDemoCallers: false,
  features: {
    enableCrowdViewMute: false,
    enableCrowdViewPop: false,
  },
  filtersAnd: [],
  filtersOr: [],
  hostedNetworks: [],
  housekeepingPerformed: false,
  housekeepingAcknowledged: false,
  initialFiltersAnd: [],
  initialFiltersOr: [],
  isEpisodeSelected: false,
  locationRequired: false,
  network: null,
  nextomeetProducerUrl: null,
  noiseOn: false,
  page: "Producer",
  participants: [],
  participantsToDisplay: {},
  productionEvents: [],
  programCMP: false,
  recovery: false,
  roleChoices: [
    {
      name: "callScheduler",
      alias: "Call Scheduler",
      value: false,
    },
    {
      name: "callProducer",
      alias: "Call Producer",
      value: false,
    },
    {
      name: "powerUser",
      alias: "Power User",
      value: false,
    },
    {
      name: "queueAdmin",
      alias: "Queue Admin",
      value: false,
    },
    {
      name: "queueTech",
      alias: "Queue Tech",
      value: false,
    },
  ],
  serverTimeOffset: false,
  serverMessage: null,
  scheduleRange: {
    startDate: startOfInitialToday,
    endDate: addDays(startOfInitialToday, 7),
  },
  screeners: {},
  shows: {},
  sortMode: {
    mode: "default",
    inverted: false,
    details: {},
  },
  showInfo: {
    zoomApiAvailability: {
      available: true,
      nextAvailableDateAndTime: undefined,
    },
  },
  targetLocations: {},
  targetZip: false,
  topics: {},
  totalCallersInQueue: 0,
  userInfo: null,
  username: false,
  zipError: false,
  zoomHostUrl: null,
};

const ensureTopicsHaveANoneOption = (_topics) => {
  let tempTopics = JSON.parse(JSON.stringify(_topics));
  tempTopics[Object.keys(tempTopics).length] = {
    id: Object.keys(tempTopics).length,
    type: "Topic",
    topic: "No Topic Selected",
  };

  const ordered = {};
  Object.keys(tempTopics)
    .sort()
    .reverse()
    .forEach((key) => {
      ordered[key] = tempTopics[key];
    });

  return ordered;
};

const generateInitialFiltersOr = (_topics) => {
  let newFiltersOr = [];
  for (var t in _topics) {
    newFiltersOr.push(`topic_${_topics[t].topic}`);
  }

  return newFiltersOr;
};

//Set state, with a default state value and a switch that defines how it is modified
const rootReducer = (state = initialState, action) => {
  const rs = { ...state };

  switch (action.type) {
    case SET_CONTROL_PANELS:
      if (
        action.payload.episode.show === rs.currentShow &&
        action.payload.episode.ep === rs.currentEpisode
      ) {
        return {
          ...rs,
          screeners: action.payload.controlPanels,
        };
      } else {
        logger.warn(
          "Recieved screeners for episode that was no longer selected",
          action
        );

        return rs;
      }

    case CONTROL_PANEL_UPDATED:
      const { controlPanel } = action.payload;
      const wasCp = rs.screeners[controlPanel.name];

      const isCurrentScreener = rs.currentScreener
        ? rs.currentScreener === controlPanel.name
        : false;

      const currentScreenerWasInCall =
        isCurrentScreener &&
        wasCp.inCall !== false &&
        controlPanel.inCall === false;

      if (currentScreenerWasInCall) {
        rs.zoomHostUrl = null;
        rs.nextomeetProducerUrl = null;
      }

      return {
        ...rs,
        screeners: { ...rs.screeners, [controlPanel.name]: controlPanel },
      };

    case SET_CLIENT_CONFIGURATION:
      return { ...rs, clientConfiguration: action.payload };

    case SET_PARTICIPANTS:
      return {
        ...rs,
        participants: action.payload.participants.map((p) => ({
          ...p,
          status: determineParticipantStatus(p),
        })),
      };

    case PARTICIPANT_CREATED:
      const participant = {
        ...action.payload.participant,
        status: determineParticipantStatus(action.payload.participant),
      };

      return {
        ...rs,
        participants: [...rs.participants, participant],
      };

    case PARTICIPANT_DELETED:
      return {
        ...rs,
        participants: rs.participants.filter(
          (participant) => participant.guid !== action.payload.participantGuid
        ),
      };

    case PARTICIPANT_UPDATED: {
      const index = rs.participants.findIndex(
        ({ guid }) => guid === action.payload.participant.guid
      );

      const participant = {
        ...action.payload.participant,
        status: determineParticipantStatus(action.payload.participant),
      };

      return {
        ...rs,
        participants: Ramda.update(index, participant, rs.participants),
      };
    }

    case SET_PRODUCTION_EVENTS:
      return {
        ...rs,
        productionEvents: action.payload.productionEvents,
      };

    case PRODUCTION_EVENT_CREATED:
      return {
        ...rs,
        productionEvents: [
          ...rs.productionEvents,
          action.payload.productionEvent,
        ],
      };

    case PRODUCTION_EVENT_DELETED:
      return {
        ...rs,
        productionEvents: rs.productionEvents.filter(
          (event) => event.guid !== action.payload.productionEventGuid
        ),
      };

    case PRODUCTION_EVENT_UPDATED: {
      const { productionEvent } = action.payload;

      const index = rs.productionEvents.findIndex(
        ({ guid }) => guid === productionEvent.guid
      );

      const updatedProductionEvent = {
        ...productionEvent,
        updatedAt: Date.now(),
      };

      return {
        ...rs,
        productionEvents: Ramda.update(
          index,
          updatedProductionEvent,
          rs.productionEvents
        ),
      };
    }

    case PARTICIPANT_ADDED_TO_PRODUCTION_EVENT: {
      const { productionEventGuid, participantGuid } = action.payload;

      const index = rs.productionEvents.findIndex(
        ({ guid }) => guid === productionEventGuid
      );
      const event = rs.productionEvents.find(
        ({ guid }) => guid === productionEventGuid
      );

      const updatedProductionEvent = {
        ...event,
        participants: event.participants
          ? [participantGuid, ...event.participants]
          : [participantGuid],
        updatedAt: Date.now(),
      };

      return {
        ...rs,
        productionEvents: Ramda.update(
          index,
          updatedProductionEvent,
          rs.productionEvents
        ),
      };
    }

    case PARTICIPANT_REMOVED_FROM_PRODUCTION_EVENT: {
      const { productionEventGuid, participantGuid } = action.payload;

      const index = rs.productionEvents.findIndex(
        ({ guid }) => guid === productionEventGuid
      );
      const event = rs.productionEvents.find(
        ({ guid }) => guid === productionEventGuid
      );

      const updatedProductionEvent = {
        ...event,
        participants: event.participants?.filter(
          (guid) => guid !== participantGuid
        ),
        updatedAt: Date.now(),
      };

      return {
        ...rs,
        productionEvents: Ramda.update(
          index,
          updatedProductionEvent,
          rs.productionEvents
        ),
      };
    }

    // AUTH REDUCERS
    case ON_SOCKET_CONNECT: {
      rs.disconnected = false;
      rs.page = "Producer";
      const forcedLogoutData = localStorage.getItem("logged-out-recovery");
      if (forcedLogoutData) {
        const recovery = JSON.parse(forcedLogoutData);
        if (recovery) {
          rs.recovery = { ...recovery };
        }
        localStorage.removeItem("logged-out-recovery");
      }
      // Restore application state to before disconnection
      if (rs.recovery) {
        console.log("In recovery chain");
        if (rs.recovery.network) {
          rs.network = rs.recovery.network;
          CQ.emit("setNetworkAndGetShows", {
            networkName: rs.recovery.network,
          });
        }
        if (rs.recovery.userInfo) {
          rs.userInfo = rs.recovery.userInfo;
          CQ.emit("setUserInfo", { ...rs.recovery.userInfo });
        }
        if (rs.recovery.currentShow) {
          rs.currentShow = rs.recovery.currentShow;
          rs.episodes = rs.shows[rs.recovery.currentShow];
          rs.isShowSelected = true;
          if (rs.isEpisodeSelected) {
            rs.currentEpisode = false;
            rs.isEpisodeSelected = false;
            rs.callers = {};
          }
          CQ.emit("selectShow", {
            network: rs.recovery.network,
            show: rs.recovery.currentShow,
          });
        }
        if (rs.recovery.currentEpisode) {
          rs.currentEpisode = rs.recovery.currentEpisode;
          const UID =
            `${rs.recovery.network}-${rs.recovery.currentShow}-${rs.currentEpisode}`
              .toLowerCase()
              .replaceAll(" ", "_");
          rs.isEpisodeSelected = true;
          CQ.emit("selectEpisode", {
            episode: {
              Network: rs.recovery.network,
              Show_Name: rs.currentShow,
              Name: rs.currentEpisode,
              UID,
            }, //TODO base networkName on userInfo
          });
        }
      }
      return rs;
    }
    case SELECT_NETWORK: {
      if (rs.network !== action.payload) {
        //console.log(`Connection: Network selected: ${action.payload}`);
        rs.network = action.payload;

        const shouldShowTwilioGoneWarning =
          isAfterTwilioSoftShutoffDate &&
          isBefore(new Date(), new Date("2026-12-31T11:59:59"));

        rs.criticalError = shouldShowTwilioGoneWarning
          ? `${branding.twilio} has been removed from ${branding.airfirst} as an option.`
          : null;
        rs.serverMessage = null;
        CQ.emit("setNetworkAndGetShows", { networkName: action.payload });
      }
      return rs;
    }
    case SELECT_ROLE: {
      rs.currentRole = action.payload;
      if (action.payload.name === "queueAdmin") {
        rs.page = "Producer";
      }
      return rs;
    }
    case SELECT_SCHEDULE_RANGE: {
      rs.scheduleRange = action.payload.range;
      return rs;
    }
    case SET_USER_INFO: {
      rs.userInfo = action.payload;
      CQ.emit("setUserInfo", { ...action.payload });
      return rs;
    }
    case SET_ROLE_CHOICES: {
      rs.roleChoices = action.payload;
      return rs;
    }
    case STORE_FORCED_LOGOUT_RECOVERY_DATA: {
      rs.disconnected = true;
      rs.recovery = {};
      if (rs.currentEpisode) {
        rs.recovery.currentEpisode = rs.currentEpisode;
      }
      if (rs.currentShow) {
        rs.recovery.currentShow = rs.currentShow;
      }
      if (rs.currentScreener) {
        rs.recovery.currentScreener = rs.currentScreener;
      }
      if (rs.userInfo) {
        rs.recovery.userInfo = rs.userInfo;
      }
      if (rs.network) {
        rs.recovery.network = rs.network;
      }
      const stringifiedRecoveryData = JSON.stringify(rs.recovery);
      if (stringifiedRecoveryData === "{}") {
        rs.recovery = false;
      } else {
        localStorage.setItem("logged-out-recovery", stringifiedRecoveryData);
      }
      return rs;
    }
    case SET_HOSTED_NETWORKS: {
      rs.hostedNetworks = action.hostedNetworks;

      console.log(
        `Connection: Hosted network/account(s) retrieved: ${
          rs.hostedNetworks?.join() || "None"
        }.`
      );

      return rs;
    }

    // SESSION REDUCERS
    case SHOW_INFO: {
      rs.showInfo = action.payload;
      rs.topics = {};
      return rs;
    }
    case SERVER_OFFSET: {
      let serverTime = action.payload.valueOf();
      let clientTime = new Date().valueOf();
      let calculatedTimeOffset = serverTime - clientTime;
      rs.serverTimeOffset = calculatedTimeOffset;
      return rs;
    }

    case HOUSEKEEPING: {
      rs.housekeepingPerformed = true;
      rs.housekeepingAcknowledged = false;
      return rs;
    }

    case HOUSEKEEPING_ACK: {
      rs.housekeepingAcknowledged = true;
      return rs;
    }

    //QUEUE REDUCERS
    case TOGGLE_AUDIO: {
      rs.noiseOn = !rs.noiseOn;
      return rs;
    }
    case SET_FILTERS: {
      rs.filtersAnd = action.payload.filtersAnd.sort();
      rs.filtersOr = action.payload.filtersOr.sort();
      rs.participantsToDisplay = sortAndFilter.filter(
        rs.callers,
        rs.targetZip,
        rs.filtersAnd,
        rs.filtersOr
      );
      return rs;
    }
    case SET_ZIP: {
      rs.targetZip = action.payload;
      rs.zipError = false;
      rs.participantsToDisplay = sortAndFilter.filter(
        rs.callers,
        rs.targetZip,
        rs.filtersAnd,
        rs.filtersOr
      );
      return rs;
    }
    case ADD_QUEUE_PARTICIPANT: {
      const { payload: participant } = action;
      if (!participant) return rs;
      const { id: participantID } = participant;
      rs.callers = { ...rs.callers, [participantID]: participant };
      rs.participantsToDisplay = sortAndFilter.filter(
        rs.callers,
        rs.targetZip,
        rs.filtersAnd,
        rs.filtersOr
      );
      rs.totalCallersInQueue = Object.entries(rs.callers).length;
      if (rs.currentScreener) {
        if (rs.screeners[rs.currentScreener].inCall) {
          return rs;
        }
      }
      if (participant.wasAlreadyInQueue === false) {
        try {
          if (rs.noiseOn) {
            document.getElementById("notificationNoise").play();
          }
        } catch (err) {
          console.error(err);
        }
      }
      return rs;
    }
    case UPDATE_QUEUE_PARTICIPANT: {
      const { payload: participant } = action;

      const { id: participantID } = participant;
      // Replace current participant with new participant data
      rs.callers = { ...rs.callers, [participantID]: participant };
      rs.participantsToDisplay = sortAndFilter.filter(
        rs.callers,
        rs.targetZip,
        rs.filtersAnd,
        rs.filtersOr
      );
      return rs;
    }
    case REMOVE_QUEUE_PARTICIPANT: {
      const { payload: participantID } = action;
      delete rs.callers[participantID];
      rs.participantsToDisplay = sortAndFilter.filter(
        rs.callers,
        rs.targetZip,
        rs.filtersAnd,
        rs.filtersOr
      );
      rs.totalCallersInQueue = Object.entries(rs.callers).length;
      return rs;
    }

    //ENDPOINT REDUCERS
    case ALLOCATE_CMP: {
      rs.screeners[rs.currentScreener].allocatedCMP = action.payload;
      return rs;
    }
    case CMP_LIST: {
      rs.cmps = action.payload;
      return rs;
    }

    case PROGRAM_CMP: {
      if (action.payload) {
        rs.programCMP = action.payload;
      } else if (action.payload === false) {
        rs.programCMP = false;
      }
      return rs;
    }
    case PARTICIPANT_EAVESDROP_STATUS: {
      if (
        rs.screeners[rs.currentScreener] &&
        rs.screeners[rs.currentScreener].allocatedCMP &&
        rs.screeners[rs.currentScreener].allocatedCMP.participantEavesDropper
      ) {
        let tempCurrentScreener = JSON.parse(
          JSON.stringify(rs.screeners[rs.currentScreener])
        );
        if (
          action.payload.status !==
            tempCurrentScreener.allocatedCMP.participantEavesDropper.status ||
          action.payload.isAutoRecording !==
            tempCurrentScreener.allocatedCMP.participantEavesDropper
              .isAutoRecording ||
          action.payload.timeStarted !==
            tempCurrentScreener.allocatedCMP.participantEavesDropper.timeStarted
        ) {
          tempCurrentScreener.allocatedCMP.participantEavesDropper.status =
            action.payload.status;
          tempCurrentScreener.allocatedCMP.participantEavesDropper.isAutoRecording =
            action.payload.isAutoRecording;
          tempCurrentScreener.allocatedCMP.participantEavesDropper.timeStarted =
            action.payload.timeStarted;

          let tempScreeners = JSON.parse(JSON.stringify(rs.screeners));
          tempScreeners[rs.currentScreener] = tempCurrentScreener;

          rs.screeners = tempScreeners;
        }
      }
      return rs;
    }
    case PROGRAM_EAVESDROP_STATUS: {
      if (rs.programCMP && rs.programCMP.programEavesDropper) {
        let newProgramCMP = JSON.parse(JSON.stringify(rs.programCMP));
        let newProgramEavesDropper = JSON.parse(
          JSON.stringify(newProgramCMP.programEavesDropper)
        );
        newProgramEavesDropper.status = action.payload.status;
        if (action.payload.timeStarted !== newProgramEavesDropper.timeStarted) {
          newProgramEavesDropper.timeStarted = action.payload.timeStarted;
        }
        newProgramCMP.programEavesDropper = newProgramEavesDropper;
        rs.programCMP = newProgramCMP;
      }
      return rs;
    }

    //TODO: Figure out how to group these
    case "CALLERS": {
      rs.callers = action.callers;

      rs.participantsToDisplay = sortAndFilter.filter(
        rs.callers,
        rs.targetZip,
        rs.filtersAnd,
        rs.filtersOr
      );
      rs.totalCallersInQueue = action.totalCallersInQueue;
      return rs;
    }
    case "CHANGE_PAGE": {
      let { newPage, screenerName } = action.payload;

      rs.page = newPage;

      // TODO test if necessary
      /*rs.zoomHostUrl = null;
      rs.nextomeetProducerUrl = null;*/

      switch (rs.page) {
        case "Producer":
          if (rs.currentScreener) {
            CQ.emit("screenerLeft", { endpointID: rs.currentScreener });
            rs.currentScreener = false;
          }
          break;
        case "Screener":
          let screener;
          for (var sKey in rs.screeners) {
            if (rs.screeners[sKey].name === screenerName) {
              screener = rs.screeners[sKey];
              break;
            }
          }
          if (!screener) return;

          if (screenerName && !screener.qManagerInControl) {
            rs.currentScreener = screenerName;
            CQ.emit("screenerSelected", { endpointID: screenerName });
          } else rs.page = state.page;
          break;
        default:
          break;
      }

      rs.zoomHostUrl = null;

      return rs;
    }
    case "CRITICAL_ERROR": {
      rs.criticalError = action.payload.message ?? null;
      return rs;
    }
    case "EPISODE_INFO": {
      let newTopics = ensureTopicsHaveANoneOption(action.epInfo.topics);
      rs.locationRequired = action.epInfo.locationRequired;
      rs.targetLocations = action.epInfo.targetLocations;

      if (!Ramda.equals(rs.topics, newTopics)) {
        // If topics changed we MUST reset the topic filters
        rs.topics = newTopics;

        rs.initialFiltersOr = generateInitialFiltersOr(newTopics);
        rs.initialFiltersOr.sort();
        rs.filtersOr = rs.initialFiltersOr;
      }

      if (rs.targetZip === false) {
        if (Object.keys(rs.targetLocations).length !== 0) {
          let tagsToRemove = [];
          rs.targetZip = Object.keys(rs.targetLocations)[
            Object.keys(rs.targetLocations.length - 1)
          ];
          for (var q = 0; q < rs.filtersAnd.length; q++) {
            if (rs.filtersAnd[q].indexOf("distance_") > -1) {
              tagsToRemove.push(rs.filtersAnd[q]);
              break;
            }
          }
          rs.filtersAnd = Ramda.without(tagsToRemove, rs.filtersAnd);
        }
      }
      rs.zipError = false;
      rs.filtersAnd.sort();

      rs.participantsToDisplay = sortAndFilter.filter(
        rs.callers,
        rs.targetZip !== undefined ? rs.targetZip : false,
        rs.filtersAnd,
        rs.filtersOr
      );

      rs.screeners = action.epInfo.Endpoints || {};

      /**
       * @todo Make the server handle control panel recovery
       */
      if (rs.recovery.currentScreener) {
        let controlPanelToRecover;

        for (const controlPanel in rs.screeners) {
          if (rs.screeners[controlPanel].name === rs.recovery.currentScreener) {
            controlPanelToRecover = rs.screeners[controlPanel];
            break;
          }
        }

        if (controlPanelToRecover && !controlPanelToRecover.qManagerInControl) {
          rs.page = "Screener";
          rs.currentScreener = rs.recovery.currentScreener;
          CQ.emit("screenerSelected", { endpointID: rs.currentScreener });
        } else {
          rs.currentScreener = false;
          rs.page = "Producer";
        }
        rs.recovery = false;
      }

      rs.episodeAllowingNewQueueParticipants =
        action.epInfo.allowingNewQueueParticipants;
      rs.episodeAllowsDemoCallers = action.epInfo.allowsDemoCallers;
      rs.features = {
        ...rs.features,
        enableCrowdViewMute:
          action.epInfo?.crowdViewConfig?.enableMuteFeature ?? false,
        enableCrowdViewPop:
          action.epInfo?.crowdViewConfig?.enablePopFeature ?? false,
      };
      //rs.attemptedQueueJoinsCount = action.epInfo.attemptedQueueJoinsCount;
      return rs;
    }
    case "KICKED_BY_PRODUCER": {
      rs.currentScreener = false;
      rs.page = "Producer";
      return rs;
    }
    case "NEXTOMEET_PRODUCER_URL": {
      rs.nextomeetProducerUrl = action.payload.producerUrl;
      return rs;
    }
    case "SELECT_SHOW": {
      if (rs.currentShow === action.show) return rs;
      rs.zoomHostUrl = null;
      rs.criticalError = null;
      rs.filtersAnd = [];
      rs.filtersOr = [];
      rs.housekeepingAcknowledged = false;
      rs.housekeepingPerformed = false;
      rs.initialFiltersAnd = [];
      rs.initialFiltersOr = [];
      rs.targetLocations = {};
      rs.targetZip = false;
      rs.locationRequired = false;
      rs.sortMode = {
        mode: "default",
        inverted: false,
        details: {},
      };
      rs.currentShow = action.show;
      rs.episodes = rs.shows[action.show];
      rs.isShowSelected = true;
      rs.topics = [];
      let wasScreener = false;
      if (rs.currentScreener) {
        if (Object.keys(rs.shows[rs.currentShow]).length === 1)
          wasScreener = rs.currentScreener;
        else CQ.emit("screenerLeft", { endpointID: rs.currentScreener });
        rs.currentScreener = false;
        rs.page = "Producer";
      }
      if (rs.isEpisodeSelected) {
        rs.currentEpisode = false;
        rs.isEpisodeSelected = false;
        rs.callers = {};
      }
      rs.programCMP = false;
      CQ.emit("selectShow", { network: "VCC", show: rs.currentShow }); //TODO base networkName on userInfo
      if (Object.keys(rs.shows[rs.currentShow]).length === 1) {
        rs.currentEpisode = Object.keys(rs.shows[rs.currentShow])[0];
        rs.isEpisodeSelected = true;
        if (wasScreener && rs.currentShow === state.currentShow) {
          const oldUID =
            `${rs.network}-${state.currentShow}-${state.currentEpisode}`
              .toLowerCase()
              .replaceAll(" ", "_");
          const newUID = `${rs.network}-${rs.currentShow}-${rs.currentEpisode}`
            .toLowerCase()
            .replaceAll(" ", "_");
          CQ.emit("screenerSelectEpisode", {
            endpointID: wasScreener,
            oldEp: {
              net: rs.network,
              show: state.currentShow,
              ep: state.currentEpisode,
              UID: oldUID,
            },
            newEp: {
              net: rs.network,
              show: rs.currentShow,
              ep: rs.currentEpisode,
              UID: newUID,
            },
          });
        } else {
          const UID = `${rs.network}-${rs.currentShow}-${rs.currentEpisode}`
            .toLowerCase()
            .replaceAll(" ", "_");
          CQ.emit("selectEpisode", {
            episode: {
              Network: rs.network,
              Show_Name: rs.currentShow,
              Name: rs.currentEpisode,
              UID,
            },
          });
        }
      }
      return rs;
    }
    case "SHOWS": {
      rs.shows = action.shows;
      if (rs.isShowSelected) {
        if (rs.shows[rs.currentShow]) {
          rs.episodes = rs.shows[rs.currentShow];
          if (rs.isEpisodeSelected) {
            if (!rs.episodes[rs.currentEpisode]) {
              if (rs.currentEpisode) {
                rs.currentEpisode = false;
              }
              if (rs.isEpisodeSelected) {
                rs.isEpisodeSelected = false;
              }
            }
          }
        } else {
          if (rs.currentShow) {
            rs.currentShow = false;
          }
          if (rs.currentEpisode) {
            rs.currentEpisode = false;
          }
          if (rs.isShowSelected) {
            rs.isShowSelected = false;
          }
          if (rs.isEpisodeSelected) {
            rs.isEpisodeSelected = false;
          }
          if (rs.episodes) {
            rs.episodes = {};
          }
          if (rs.screeners) {
            rs.screeners = {};
          }
          if (rs.callers) {
            rs.callers = {};
          }
          rs.page = "Producer";
        }
      }
      //If there is one show, select that show
      if (Object.keys(rs.shows).length === 1) {
        rs.currentShow = Object.keys(rs.shows)[0];
        rs.episodes = rs.shows[rs.currentShow];
        rs.isShowSelected = true;
        if (rs.isEpisodeSelected) {
          rs.currentEpisode = false;
          rs.isEpisodeSelected = false;
          rs.callers = {};
        }
        CQ.emit("selectShow", {
          network: "VCC", //TODO base networkName on userInfo,
          show: rs.currentShow,
        });
        if (Object.keys(rs.shows[rs.currentShow]).length === 1) {
          rs.currentEpisode = Object.keys(rs.shows[rs.currentShow])[0];
          const UID = `${rs.network}-${rs.currentShow}-${rs.currentEpisode}`
            .toLowerCase()
            .replaceAll(" ", "_");
          rs.isEpisodeSelected = true;
          CQ.emit("selectEpisode", {
            episode: {
              Network: "VCC",
              Show_Name: rs.currentShow,
              Name: rs.currentEpisode,
              UID,
            },
          }); //TODO base networkName on userInfo
        }
      }
      return rs;
    }
    case "ZIP_ERROR": {
      rs.zipError = action.error;
      return rs;
    }
    case "ZOOM_HOST_URL": {
      rs.zoomHostUrl = action.payload.zoomHostUrl;
      return rs;
    }
    case "ZOOM_AVAILABILITY_CHANGE": {
      const { available, nextAvailableDateAndTime } = action.payload;

      if (rs.clientConfiguration?.enableZoom === false) {
        return rs;
      }

      if (
        rs.showInfo.zoomApiAvailability.available === true &&
        available === false
      ) {
        // The below had to be done because I cannot access the userPrefs reducer from within the queue reducer. AM 1/23/2024
        const localStorageDisplayMilitaryItem = localStorage.getItem(
          localStorageKey.militaryTime
        );
        const localStorageTimeZone = localStorage.getItem(
          localStorageKey.timeZone
        );

        const displayMilitary = localStorageDisplayMilitaryItem === "true";
        const timeZone = localStorageTimeZone
          ? decodeURI(localStorageTimeZone)
          : localTimeZone;

        const dateAndTimeFormat = displayMilitary
          ? scheduledCallDateAndTimeFormat.military
          : scheduledCallDateAndTimeFormat.standard;

        rs.criticalError = `This ${branding.airfirst} platform's ${
          branding.zoom
        } API daily limit was exceeded.  ${
          branding.zoom
        } calls will not be available as an option until ${dateToFormattedString(
          nextAvailableDateAndTime,
          dateAndTimeFormat,
          timeZone
        )}.`;
        rs.serverMessage = null;
      } else if (
        rs.showInfo.zoomApiAvailability.available === false &&
        available === true
      ) {
        rs.criticalError = null;
        rs.serverMessage = `This ${branding.airfirst} platform's ${branding.zoom} API daily limit was reset and ${branding.zoom} calls are available as an option again.`;
      }
      rs.showInfo.zoomApiAvailability = action.payload;
      return rs;
    }
    case "ZOOM_SUPPRESS_PRESCREEN_WARNING": {
      const newSuppressUntilDate = addDays(new Date(), 1).toISOString();
      localStorage.setItem(
        localStorageKey.suppressZoomWarning,
        newSuppressUntilDate
      );
      rs.displayZoomPrescreenWarning = false;
      return rs;
    }
    default: {
      return rs;
    }
  }
};

export default rootReducer;
